@font-face {
  font-family: "VCR";
  src: url("../fonts/VCR_OSD_MONO_1.001.ttf");
}

@font-face {
  font-family: "Abel";
  src: url("../fonts/Abel-Regular.ttf");
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "VCR", Arial, sans-serif;
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;

  font-family: "VCR", Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
}

/* this allows everything to be centered horizontally */
.full-width-column {
  height: 100%;
  width: 100%;
  background-color: rgb(250, 250, 76);
  position: absolute;
  top: 0;
  left: 0;
}

/* https://stackoverflow.com/questions/2941189/how-to-overlay-one-div-over-another-div */
.main-content-container {
  position: relative;
  box-sizing: border-box; /* Include padding and border in element's width and height */
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

#scene-container {
  /* positioning */
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

#overlay {
  /* positioning */
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}

#instructions-overlay {
  /* positioning */
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  visibility: hidden;
}
#current-instructions {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}

#overlay > div {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

#overlay > div > button {
  display: block;
  color: transparent;
  background-color: transparent;
  border: 0px;
  cursor: pointer;
  padding: 0%;
}

#overlay > div > button > img {
  display: block;
  width: 400px;
  /* -webkit-animation: spin 12s linear infinite;
    -moz-animation: spin 12s linear infinite;
    animation: spin 12s linear infinite; */
}

#overlay > div > button > img:hover {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

.webcam-status-overlay {
  /* positioning */
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.webcam-status-overlay > .vertical-positioning-container {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.webcam-status-overlay > .vertical-positioning-container > .centered-container {
    position: relative;
    width: 160px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.webcam-status-overlay
  > .vertical-positioning-container
  > .centered-container
  > img {
  width: 40px;
  /* https://stackoverflow.com/questions/12690444/css-border-on-png-image-with-transparent-parts */
  -webkit-filter: drop-shadow(5px -1px 4px #343434);
  filter: drop-shadow(5px -1px 4px #343434);
}

#metrocard-status-image {
  margin-left: 15px;
  display: none;
}

#metrocard-status-image.active {
  display: inline-block;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bottom_bar_container {
  position: relative;
  width: 90%;
  height: 20%;
  margin-left: auto;
  margin-right: auto;
}
/* https://css-tricks.com/snippets/css/a-guide-to-flexbox/ */
.bottom_bar {
  /* position: absolute; */
  z-index: 2;
  bottom: 0;
  /* left: auto; */
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  width: 100%;
  /* background-color: rgb(250, 250, 76); */
  color: black;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  /* Then we define how is distributed the remaining space */
  justify-content: space-around;
  padding: 0;
}

.bottom_bar > img {
  position: absolute;
  z-index: 3;
}

.bottom_bar > div {
  border: 10px inset grey;
  background-color: rgb(72, 72, 72);
  color: white;
  font-size: 10px;
  border-collapse: separate;
  padding: 0%;
  height: 100%;
  width: 21.25%;
  margin: 0px;
  font-size: 0.85em;
  box-sizing: border-box; /* Include padding and border in element's width and height */
  text-align: center;
  overflow: hidden;
}

.box-title {
  font-weight: bold;
  margin: 1%;
}

.box-content {
  font-size: 12px;
  text-align: left;
  margin: 5%;
}
.box-content > a:link {
  outline: none;
  text-decoration: none;
  color: yellow;
}
.box-content > a:visited {
  color: yellow;
}
.box-content > a:hover {
  background-color: rgba(0, 0, 0, 1);
  color: yellow;
}

#self-view-box {
  width: 15%;
  display: flex;
  overflow: hidden;
  background-color: black;
  position: relative;
}

#mini-map-box {
  display: flex;
  overflow: hidden;
  background-color: black;
  position: relative;
}

#self-view-canvas-container {
  width: 260px;
  height: 200px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -130px;
  margin-top: -100px;
}

#mini-map-canvas-container {
  width: 300px;
  height: 300px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -150px;
  margin-top: -150px;
}

.scrolling-box {
  text-align: center;
  -webkit-animation: scroll 20s linear infinite;
  -moz-animation: scroll 20s linear infinite;
  animation: scroll 20s linear infinite;
}

@keyframes scroll {
  from {
    margin-top: 80%;
  }
  to {
    margin-top: -100%;
  }
}

/* Onboarding Modal */
.onboarding-modal {
  font-family: "Abel", sans-serif;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 10;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

.onboarding-modal-content {
  background-color: #fefefe;
  margin: 5% auto;
  padding: 2%;
  overflow-y: scroll;
  border: 2px solid #888;
  width: 70%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.onboarding-modal-content  h2,h3,h4,h5 {
  font-family: "VCR", Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  width: 90%;
  text-align: center;
  margin-left:auto;
  margin-right:auto;
}
#show-notes {
  background: lightblue;
  padding: 1%;
  margin:1%;
}

#show-notes h5 {
  font-family: "VCR", Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  width: 90%;
  text-align: center;
  margin-left:auto;
  margin-right:auto;
  padding:0%;
  margin-top:0%;
  margin-bottom:0%;
}

.onboarding-modal-content  p {
    width: 80%;
    margin-left:auto;
    margin-right:auto;
  }

#instructions img {
  width: 100px;
}

#instructions {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden; /* will contain if #first is longer than #second */
  text-align: center;
}

#movement-instructions {
  width: 30%;
  float: left; /* add this */
}

#look-instructions {
  overflow: hidden; /* if you don't want #second to wrap below #first */
}

#user-media-button {
  display: block;
  padding:2%;
  margin-top:5%;
  margin-bottom:5%;
  margin-left: auto;
  margin-right: auto;
  width: 30%;
  max-height: 220px;
  font-family: "VCR", Arial, sans-serif;
  box-shadow: 0 16px 24px 0 rgba(53, 51, 214, 0.493), 0 6px 20px 0  rgba(53, 51, 214, 0.493);
}

#user-media-button:hover {
    font-size: 16px;
}

#startButton {
  display: block;
  color: transparent;
  background-color: transparent;
  border: 0px;
  cursor: pointer;
  padding: 0%;
  margin-left:auto;
  margin-right:auto;
}

#startButton > img {
    padding:2%;
    margin-top:3%;
    margin-bottom:3%;
  display: block;
  width: 140px;
  margin-left:auto;
  margin-right:auto;
  /* -webkit-animation: spin 12s linear infinite;
      -moz-animation: spin 12s linear infinite;
      animation: spin 12s linear infinite; */
}

#startButton > img:hover {
    width: 160px;
  /* -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite; */
}

/* PROJECT MODAL */
/* https://www.w3schools.com/howto/howto_css_modals.asp */
/* The Modal (background) */
.project-modal {
  /* display: none; */
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 10;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content/Box */
.project-modal-content {
  background-color: #fefefe;
  margin: 5% auto;
  overflow-y: scroll;
  /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
  /* Could be more or less, depending on screen size */
}

.project-modal-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  /* width: 30%; */
  max-height: 220px;
}

.project-modal-title {
  text-align: center;
}

.project-modal-names {
  text-align: center;
  font-size: 14px;
}

.project-modal-text {
    font-size: 14px;
    line-height: 17px;
    font-family: monospace;
    width:80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    text-align: center;
}

.project-modal-links-header{
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}
.project-modal-link-container {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.project-modal-link-container a {
    margin: auto;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Audio Button */
.audio-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-audio {
  position: relative;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-image: url(../images/musicswitch.png);
  background-size: 40px;
}

.btn-audio.is-active {
  background-position: 0 0;
}

.btn-audio.is-inactive {
  background-position: 0 -40px;
}
